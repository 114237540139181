import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import {
  Image as ChakraImage,
  Box,
  Container as ContainerChakra,
  Text,
  Heading,
  TabList,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
  Stack,
  SimpleGrid,
  Flex,
  Spacer,
  HStack
} from '@chakra-ui/react';

import WebsiteButton from '../components/WebsiteButton';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Image from '../components/Image';
import NextWebinar from '../components/NextWebinar';
import Christian from '../images/avis/christian.png';

import rating from '../images/avis/five-stars.png';
import FooterBanner from '../components/FooterBanner/FooterBanner';

const TemoignagePage = () => {
  const { googlePlacesPlace, allClientJson } = useStaticQuery(graphql`
    query {
      googlePlacesPlace {
        name
        rating
        childrenGooglePlacesReview {
          author_name
          text
          rating
          relative_time_description
          profile_photo_url
        }
        user_ratings_total
      }
      allClientJson(limit: 6) {
        nodes {
          id
          text
          author
          avatar
          link
        }
      }
    }
  `);

  const reviews = googlePlacesPlace.childrenGooglePlacesReview.map(r => (
    <ContainerChakra
      key={r.text}
      backgroundColor="white"
      borderRadius="lg"
      py={5}
    >
      <HStack>
        <Box>
          <ChakraImage
            h="60px"
            w="60px"
            src={r.profile_photo_url}
            alt="profil"
          />
        </Box>
        <Box>
          <Box>
            <Text>{r.author_name}</Text>
          </Box>
          <Flex alignItems="center">
            <ChakraImage
              width="98px"
              src={rating}
              alt={`${r.author_name} rating`}
            />
            <Text fontSize="xs" ml="5px">
              {r.relative_time_description}
            </Text>
          </Flex>
        </Box>
      </HStack>

      <Text mt="10px">{`${r.text.substring(0, 270)} ...`}</Text>
    </ContainerChakra>
  ));

  const renderFeedback = allClientJson.nodes.map(a => (
    <ContainerChakra
      key={a.id}
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginBottom={8}
    >
      <Box position="absolute" width="116px" height="116px">
        <Image
          src={a.avatar}
          css={{
            width: '100%',
            height: '100%',
            borderRadius: 116
          }}
          cover
        />
      </Box>

      <Box marginTop="58px" borderRadius="lg" padding={5} pt="80px" bg="white">
        <Text fontSize="2xl" fontWeight={600}>
          {a.text}
        </Text>
        <Text
          dangerouslySetInnerHTML={{ __html: a.author }}
          marginTop={4}
          fontSize="sm"
          letterSpacing="1"
          color="#000"
        />
        {a.link !== '' && (
          <Box mt={3}>
            <Text
              as="a"
              fontWeight="semibold"
              fontSize="sm"
              color="brand.400"
              textDecoration="underline"
              href={a.link}
              rel="noopener noreferrer"
              target="_blank"
            >
              en savoir plus &gt;&gt;
            </Text>
          </Box>
        )}
      </Box>
    </ContainerChakra>
  ));

  return (
    <Layout>
      <SEO
        title=" Web2vi | Témoignages - Ce que disent nos clients"
        description="Nos clients parlent de nos solutions bâtiment mieux que nous. Tous les témoignages de l’utilisation du logiciel et du drone sur le terrain par des pros du bâtiment."
      />
      <Box as="header" bg="white">
        <ContainerChakra maxWidth="6xl">
          <Flex
            pt={6}
            pb="85px"
            position="relative"
            alignItems="center"
            minHeight={{ base: '400px', lg: '550px' }}
          >
            <ChakraImage
              position="absolute"
              bottom={0}
              right={{ base: '-10%', xl: '-15%' }}
              height="95%"
              display={{ base: 'none', lg: 'block' }}
              src={Christian}
            />

            <Box maxWidth="xl" position="relative" paddingBottom={10}>
              <Heading as="h1" mb={5}>
                <Text as="span" color="brand.400">
                  Découvrez
                </Text>{' '}
                ce que nos clients pensent de nous
              </Heading>
              <Text mb={12}>
                Toujours en contact avec nos clients pour écouter leurs avis,
                remarques et propositions.
              </Text>
              <WebsiteButton
                href="/fonctionnalites-logiciel/"
                variant="outline"
              >
                En savoir plus sur nos solutions
              </WebsiteButton>
            </Box>
          </Flex>
        </ContainerChakra>
      </Box>

      <Tabs isFitted variant="enclosed-colored" size="lg">
        <Box position="relative">
          <ContainerChakra maxWidth="6xl" marginTop="-85px">
            <TabList boxShadow="0 0px 6px rgb(0 0 0 / 20%), 0 0px 3px rgb(0 0 0 / 17%)">
              <Tab
                fontSize="2xl"
                fontWeight={600}
                py={6}
                color="gray.800"
                border="none"
                bg="brand.400"
                _selected={{
                  color: 'gray.800',
                  bg: '#F4F6F7',
                  boxShadow: 'none'
                }}
              >
                Avis
              </Tab>
              <Tab
                fontSize="2xl"
                fontWeight={600}
                py={6}
                border="none"
                color="gray.800"
                bg="brand.400"
                _selected={{
                  color: 'gray.800',
                  bg: '#F4F6F7',
                  boxShadow: 'none'
                }}
              >
                Témoignages
              </Tab>
            </TabList>
          </ContainerChakra>
        </Box>
        <TabPanels>
          <TabPanel p={0}>
            <Box
              bg="#F4F6F7"
              width="100%"
              py={12}
              marginTop="-1px"
              position="relative"
            >
              <ContainerChakra maxWidth="4xl">
                <Stack
                  direction={{ base: 'column', lg: 'row' }}
                  spacing={3}
                  alignItems="center"
                >
                  <Text>
                    Retrouvez tous les avis de nos clients laissés sur{' '}
                    <Text
                      as="a"
                      fontWeight="semibold"
                      color="brand.500"
                      textDecoration="underline"
                      href="https://www.google.com/maps/place/WEB2VI/@47.3834895,0.6963477,17z/data=!3m1!4b1!4m5!3m4!1s0x47fd2eeff4ae9c45:0xa35f0be6edced388!8m2!3d47.3834895!4d0.6985364"
                    >
                      Google
                    </Text>
                  </Text>
                  <Spacer />
                  <Box>
                    <WebsiteButton href="https://info.web2vi.com/demande-de-d%C3%A9monstration">
                      Je demande ma démo gratuite
                    </WebsiteButton>
                  </Box>
                </Stack>
              </ContainerChakra>
            </Box>
            <Box bg="gray.800">
              <ContainerChakra
                width={{ base: null, lg: '90%' }}
                maxWidth="8xl"
                py={20}
              >
                <SimpleGrid
                  columns={{
                    base: 1,
                    md: 2,
                    lg: 3
                  }}
                  spacing={{
                    base: 6,
                    lg: 20
                  }}
                >
                  {reviews}
                </SimpleGrid>
              </ContainerChakra>
            </Box>
          </TabPanel>
          <TabPanel p={0}>
            <Box
              bg="#F4F6F7"
              width="100%"
              py={12}
              marginTop="-1px"
              position="relative"
            >
              <ContainerChakra maxWidth="4xl">
                <Stack
                  direction={{ base: 'column', lg: 'row' }}
                  spacing={3}
                  alignItems="center"
                >
                  <Text>
                    Retrouvez les témoignages de nos clients en direct depuis
                    leurs entreprises du bâtiment.
                  </Text>
                  <Spacer />
                  <Box>
                    <WebsiteButton href="https://info.web2vi.com/demande-de-d%C3%A9monstration">
                      Je demande ma démo gratuite
                    </WebsiteButton>
                  </Box>
                </Stack>
              </ContainerChakra>
            </Box>
            <Box bg="gray.800">
              <ContainerChakra
                width={{ base: null, lg: '90%' }}
                maxWidth="8xl"
                py={20}
              >
                <SimpleGrid
                  columns={{
                    base: 1,
                    xl: 3
                  }}
                  spacing={12}
                >
                  {renderFeedback}
                </SimpleGrid>
              </ContainerChakra>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <NextWebinar theme="light" />
      <FooterBanner />
    </Layout>
  );
};

export default TemoignagePage;
